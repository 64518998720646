<template>
  <!-- <surround-box v-show="areaName">
    <div style="padding: 8px">
      <div class="item-row">
        <div class="item-row">
          <span>养殖企业：</span>
          <span
            >{{ areaData.userFarmers ? areaData.userFarmers : 0 }} (个)</span
          >
        </div>
        <span>常年存栏量：</span>
        <span
          >{{
            areaData.annualStock ? areaData.annualStock : 0
          }}
          (头/只/羽)</span
        >
      </div>
      <div class="item-row">
        <span>现畜禽存栏总数：</span>
        <span>{{ areaData.tsolap ? areaData.tsolap : 0 }} (头/只/羽)</span>
      </div>
      <div class="item-row">
        <span>存栏生猪当量数：</span>
        <span>{{ areaData.nobeis ? areaData.nobeis : 0 }} (头)</span>
      </div>
      <div class="item-row">
        <span>粪污综合利用量：</span>
        <span
          >{{
            areaData.combinedUseOfFecalWaste
              ? Number(areaData.combinedUseOfFecalWaste)
              : 0
          }}
          (吨)</span
        >
      </div>
      <div class="item-row">
        <span>有机肥综合生产量：</span>
        <!-- .toFixed(2) 
        <span
          >{{
            areaData.comprehensiveOrganicFertilizerProduction
              ? Number(
                  areaData.comprehensiveOrganicFertilizerProduction
                )
              : 0
          }}
          (吨)</span
        >
      </div>
      <div class="item-row">
        <span>粪污产生量：</span>
        <span
          >{{
            areaData.fecalWasteProduction ? areaData.fecalWasteProduction : 0
          }}
          (吨)</span
        >
      </div>
      <div class="item-row">
        <span>现商品畜禽存栏：</span>
        <span>{{ areaData.soclap ? areaData.soclap : 0 }} (头/只/羽)</span>
      </div>
    </div>
  </surround-box> -->
  <infowindow ref="infowindow"></infowindow>
</template>
<script>
import areaList from "./data.json";
// import surroundBox from "@/components/mapComp/surroundBox";
import Infowindow from "@/components/cesiumMap/Infowindow";
export default {
  props: {
    areaName: String,
    position: Object,
  },
  components: {
    // surroundBox,
    Infowindow,
  },
  data() {
    return {
      showData: {
        guimo: "",
        tudi: "",
        bili: "",
      },
      areaData: [],
    };
  },
  watch: {
    areaName() {
      // this.getAreaInfo();
      this.getArea();
    },
  },
  methods: {
    //   查询区域信息
    // getAreaInfo() {
    //   if (!this.areaName) return;
    //   let area = areaList.find((item) => item.name == this.areaName);
    //   if (!area) {
    //     this.showData = {
    //       guimo: "",
    //       tudi: "",
    //       bili: "",
    //     };
    //     return;
    //   }
    //   this.$post(this.$api.STATICS_MANAGE.GET2, {
    //     resourceTypeId: area.id,
    //   }).then((res) => {
    //     res.forEach((item) => {
    //       if (item.dimensionName == "养殖规模") {
    //         this.showData.guimo = item.content;
    //       }
    //       if (item.dimensionName == "消纳土地规模") {
    //         this.showData.tudi = item.content;
    //       }
    //       if (item.dimensionName == "资源化利用比例") {
    //         this.showData.bili = item.content;
    //       }
    //     });
    //   });
    // },

    getArea() {
      if (!this.areaName) return;
      this.$post(this.$api.FAMRMING.REGION, {
        regionName: this.areaName,
      }).then((res) => {
        this.areaData = res;
      });
    },
    // show() {
    //   if (!this.position) this.$refs.infowindow.close();
    //   else {
    //     this.$refs.infowindow.show(this.position);
    //   }
    // },
  },
  mounted() {
    // this.getAreaInfo();
    this.getArea();
  },
};
</script>
<style lang='less' scoped>
.item-row {
  color: #fff;
  font-size: 18px;
}
</style>